import React from 'react';
import styles from './About.module.css';
import logo from './img/cfu_bird.jpg';
import group from './img/group_of_IT_professionals.jpg';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#0055bb';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  return (
    <div className="container aboutContent">
      <div className="row position-relative justify-content-center d-flex flex-column flex-lg-row flex-column-reverse">
        <div className="col-lg-12 pb-2">
          <>
            <h1 className="text-white text-start py-5">
              Time to think on the next steps for Ukraine
            </h1>
          </>
        </div>
      </div>

      <div className="row position-relative justify-content-center d-flex flex-column flex-lg-row flex-column-reverse">
        <div className="col-lg-7">
          <p className="text-white fs-5">
            {/* Sheila has over 15 years of experience in the education and
              technology industries, and she seeks to combine the best of both
              sectors to create a future of learning that is engaging and
              impactful. Her expertise, developed through this diverse range of
              experiences, incorporates front-end development skills, a
              user-centric approach, cultural adaptability, and a commitment to
              continuous learning. This combination creates a valuable asset for
              engaging impactful learning experiences for organizations and
              students. */}
            We are a group of IT professionals with about 15 years of experience
            in the education and technology industries, and we seek to combine
            the best of both sectors to create a future of learning that is
            engaging and impactful. Our expertise, developed through this
            diverse range of experiences, incorporates front-end development
            skills, a user-centric approach, cultural adaptability, and a
            commitment to continuous learning. This combination creates a
            valuable asset for delivering impactful learning experiences to
            organizations and students.
          </p>
        </div>
        <div className="col-lg-5 d-flex flex-column align-items-center mt-0 ">
          <img src={group} className="w-100" />
          {/* <a
            href="mailto:codingforukraine@gmail.com"
            className={`btn btn-sm mt-3 donate_button`}
            target="_blank"
            rel="noreferrer"
          >
            <small>
              <strong>Contact me</strong>
            </small>
          </a> */}

          {/* <p className="fs-5">
            By inserting into the international field of the tech-related
            careers, Ukrainian professionals will be able to access reliable
            prospects of income building and so decisively improve family
            welfare.
          </p> */}
        </div>
      </div>
      <div className="row position-relative justify-content-center d-flex flex-column flex-lg-row flex-column-reverse mb-5">
        <p className="text-white fs-5">
          In today&apos;s world, the IT market offers opportunities that
          Ukrainians may take advantage of in the short to medium term,
          particularly through remote work, offering services to international
          companies, or engaging in large outsourcing networks.
        </p>
        <p className="text-white fs-5">
          This project is the result of a partnership with tech professionals
          from different segments of the IT market, united by the common goal of
          providing a teaching platform for Ukrainians to build or complement
          their tech skills. With this intention, we have developed a
          high-quality and convenient platform to foster the expansion of these
          abilities, specifically targeting the Ukrainian public—a
          highly-educated population naturally enthusiastic about mastering
          technology qualifications.
        </p>
        <div className="text-start pt-4">
          <Link
            to="/register"
            className="btn btn-lg donate_button text-blue shadow text-nowrap d-inline"
          >
            <strong>Start learning code for free!</strong>
          </Link>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-7 bg-white pe-5 ps-5 pt-4">
          <h1>Who are us?</h1>
          <p>
            We are a group of tech professionals from different segments of the
            IT market, united by the common cause of providing a teaching
            platform for Ukrainians to build or complement tech skills.
          </p>
          <p>
            {' '}
            Sheila Tavares is a high-profile tech professional with 15+ years of
            experience in well-known educational institutions and edtech
            startups. She is passionate about making the process of tech
            learning more comprehensible and accessible to a broader audience by
            employing elements of Instructional Design and incorporating all the
            stages related to the talent development. Currently based in Brazil,
            Sheila strongly believes everyone has a special talent to be
            unfolded or streamlined, so education is not only a source of
            individual development, but a factor of community transformation in
            a global scale.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default About;
