import React, { useEffect } from 'react';
import styles from './BuyMeACoffee.module.css';

const BuyMeACoffeeWidget = () => {
  useEffect(() => {
    // Cria um novo elemento script
    const script = document.createElement('script');

    // Define o src do script
    script.src = 'https://donorbox.org/widget.js';

    // Adiciona o script ao final do body para que seja carregado
    document.body.appendChild(script);

    // Limpa o script quando o componente for desmontado para evitar vazamento de memória
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Define propriedades paypalExpress após o script ser carregado
    if (window.donorbox && window.donorbox.props) {
      window.donorbox.props.paypalExpress = false;
    }
  }, []);

  return (
    <iframe
      src="https://donorbox.org/embed/codingforukraine?default_interval=o"
      name="donorbox"
      allowpaymentrequest="allowpaymentrequest" // eslint-disable-line react/no-unknown-property
      seamless="seamless"
      frameBorder="0"
      scrolling="no"
      height="900px"
      width="100%"
      style={{
        maxWidth: '500px',
        minWidth: '250px',
        maxHeight: 'none!important',
      }}
      className={styles.bmc}
    ></iframe>
  );
};

export default BuyMeACoffeeWidget;
