// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAgFKm5hkwlYiHaEUp_lFN7KrpVFclzVh8',
  authDomain: 'coding-for-ukraine.firebaseapp.com',
  projectId: 'coding-for-ukraine',
  storageBucket: 'coding-for-ukraine.appspot.com',
  messagingSenderId: '359616851034',
  appId: '1:359616851034:web:0d1ca858f89f9cb3733c63',
  measurementId: 'G-Y93SJPLNT6',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };
