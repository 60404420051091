import MarkdownEditor from '@uiw/react-markdown-editor';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';
import { useInsertDocument } from '../../hooks/useInsertDocument';
import { useFetchDocuments } from '../../hooks/useFetchDocuments';
import { useFetchSavedPath } from '../../hooks/useFetchSavedPath';
import { useParams } from 'react-router-dom';

const CreateSection = () => {
  let { course } = useParams();
  if (!course) {
    course = 'introducing_programming';
  }
  const [moduleId, setModuleId] = useState('');
  const [module, setModule] = useState('');
  const [moduleOrd, setModuleOrd] = useState('');
  const [section, setSection] = useState('');
  const [ordination, setOrdination] = useState('');
  const [formError, setFormError] = useState('');
  const [value, setValue] = useState('');
  const [slug, setSlug] = useState('');

  const { user } = useAuthValue();

  const { insertDocument, response } = useInsertDocument('sections');
  const { documents: modules, loading } = useFetchDocuments('modules', course);
  const { documents: courses } = useFetchSavedPath('courses');

  const navigate = useNavigate();

  function onChange(event) {
    setValue(event.target.value);
    setModuleId(event.target.value);
    setModuleOrd(
      event.target.options[event.target.selectedIndex].getAttribute('data-key'),
    );
    setModule(event.target.options[event.target.selectedIndex].text);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // setFormError('');

    //checar todos os valores
    // if (!title || !course || !module || !section || !lesson || !body) {
    //   setFormError('Please, fill all the fields!');
    // }

    if (formError) {
      return;
    } else {
      insertDocument({
        course,
        module,
        moduleId,
        moduleOrd,
        section,
        ordination: Number(ordination),
        slug,
      });
    }

    //redirect
    navigate('/');
  };

  return (
    <div className="container-fluid bg-white">
      <h1>Create Section</h1>
      <form onSubmit={handleSubmit} className="w-100">
        <div className="row">
          <label className="col-3">
            <span>Course: {course}</span>
          </label>
          <label className="col-7">
            <span>Module:</span>
            {loading && <p>Loading...</p>}

            <select
              className="form-select m-1"
              onChange={onChange}
              defaultValue={'DEFAULT'}
            >
              <option value="DEFAULT" disabled>
                Choose the Module for this Section
              </option>
              {modules &&
                modules.map((module) => (
                  <option
                    key={module.ordination}
                    data-key={module.ordination}
                    value={module.id}
                  >
                    Module {module.ordination} - {module.module}
                  </option>
                ))}
            </select>

            {modules && modules.length === 0 && (
              <div className="danger">
                <p>No Module were found</p>
              </div>
            )}
          </label>
        </div>
        <div className="row">
          <label className="col-5">
            <span>Section:</span>
            <input
              type="text"
              name="section"
              className="m-1 p-2 w-100"
              required
              placeholder="section"
              onChange={(e) => setSection(e.target.value)}
              value={section}
            />
          </label>
          <label className="col-2">
            <span>Slug:</span>
            <input
              type="text"
              name="slug"
              className="m-1 p-2 w-100"
              required
              placeholder="slug"
              onChange={(e) => setSlug(e.target.value)}
              value={slug}
            />
          </label>
          <label className="col-3">
            <span>Ordination:</span>
            <select
              className="form-select m-1"
              onChange={(e) => setOrdination(e.target.value)}
              defaultValue={'DEFAULT'}
            >
              <option value="DEFAULT" disabled>
                Choose the Order for this Module ...
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </label>
          <label className="col-2 d-flex justify-content-center">
            {!response.loading && (
              <button className="btn btn-primary m-1 mt-3">Submit</button>
            )}
            {response.loading && (
              <button className="btn" disabled>
                Wait...
              </button>
            )}
            {response.error && <p className="error">{response.error}</p>}
            {formError && <p className="error">{formError}</p>}
          </label>
        </div>

        {/* <label>
          <span>Tags:</span>
          <input
            type="text"
            name="tags"
            required
            placeholder="Insert tags"
            onChange={(e) => setTags(e.target.value)}
            value={tags}
          />
        </label> */}
      </form>
    </div>
  );
};

export default CreateSection;
