import React, { useState, useEffect } from 'react';
import { usePaginationRange, DOTS } from '../../../hooks/usePaginationRange';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFetchDocuments } from '../../../hooks/useFetchDocuments';

const Pagination = ({
  data,
  RenderComponent,
  ordLesson,
  moduleSlug,
  sectionSlug,
  slug,
  course,
  buttonConst,
  contentPerPage,
  siblingCount,
}) => {
  const [totalPageCount] = useState(Math.ceil(data.length / contentPerPage));
  const { documents: modules } = useFetchDocuments('modules', course);
  const { documents: sections } = useFetchDocuments('sections', course);

  const currentModule = () => {
    const filteredModule = modules?.find((mod) => mod?.slug === moduleSlug);

    return filteredModule
      ? { ordination: filteredModule?.ordination, id: filteredModule?.id }
      : null;
  };

  const currentSection = () => {
    const filteredModule = modules?.find((mod) => mod.slug === moduleSlug);
    if (filteredModule) {
      const totalSections = sections?.filter(
        (section) => section.moduleId === filteredModule.id,
      ).length;
      const filteredSection = sections?.find(
        (section) => section.slug === sectionSlug,
      );

      return {
        ordination: filteredSection?.ordination,
        id: filteredSection?.id,
        slug: filteredSection?.slug,
        totalSections,
      };
    }
    return null;
  };

  const nextModule = () => {
    const next = currentModule()?.ordination + 1;
    const filteredModule = modules?.find((mod) => mod?.ordination === next);
    return filteredModule
      ? { id: filteredModule.id, slug: filteredModule.slug }
      : null;
  };

  const nextSection = () => {
    const currModule = currentModule();

    const sectionsOfModule = sections?.filter(
      (section) => section?.moduleId === currModule?.id,
    );

    const currSection = currentSection();

    if (Number(currSection?.ordination) === currSection?.totalSections) {
      const filteredNextModule = modules?.find(
        (mod) => mod?.ordination === currModule?.ordination + 1,
      );

      const firstSectionOfNextModule = sections?.find(
        (section) => section?.moduleId === filteredNextModule?.id,
      );

      return firstSectionOfNextModule ? firstSectionOfNextModule?.slug : null;
    }

    const next = Number(currSection?.ordination) + 1;

    const filteredSection = sectionsOfModule?.filter(
      (section) => section?.ordination == next,
    );

    return filteredSection ? filteredSection[0]?.slug : null;
  };

  let slugData = [];

  data.forEach(function (item) {
    slugData.push(item.slug);
  });

  const [currentPage, setCurrentPage] = useState(Number(ordLesson));
  const navigate = useNavigate();

  const paginationRange = usePaginationRange({
    totalPageCount,
    contentPerPage,
    buttonConst,
    siblingCount,
    currentPage,
  });

  const isLastLesson = currentPage === totalPageCount;
  const isLastModule = modules?.length === currentModule()?.ordination;
  const resultSection = currentSection();
  const isLastSection =
    Number(resultSection?.ordination) === resultSection?.totalSections;

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: '0px',
    });
  }, [currentPage]);

  function goToNextPage() {
    const nextPage = currentPage + 1;

    if (currentPage === totalPageCount) {
      if (!isLastSection) {
        const path = `/open/lesson/${course}/${moduleSlug}/${nextSection()}/${
          slugData[0]
        }/1`;
        navigate(path);
        window.location.reload();
      } else {
        const nModule = nextModule();
        const path = `/open/lesson/${course}/${
          nModule?.slug
        }/${nextSection()}/${slugData[0]}/1`;
        navigate(path);
        window.location.reload();
      }
    } else {
      const path = `/open/lesson/${course}/${moduleSlug}/${sectionSlug}/${
        slugData[currentPage]
      }/${currentPage + 1}`;
      navigate(path);
      window.location.reload();
    }
  }

  function goToPreviousPage() {
    const previousPage = currentPage - 1;
    const path = `/open/lesson/${course}/${moduleSlug}/${sectionSlug}/${
      slugData[previousPage - 1]
    }/${previousPage}`;

    navigate(path);
    window.location.reload();
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * contentPerPage - contentPerPage;
    const endIndex = startIndex + contentPerPage;
    return data.slice(startIndex, endIndex);
  };

  return (
    <div className="container-full bg-white pb-3 position-relative">
      <div className="dataContainer ps-4 pe-3">
        {getPaginatedData().map((dataItem, index) => (
          <RenderComponent key={index} data={dataItem} />
        ))}
      </div>
      <div className="container-full fixed-bottom position-fixed p-2 bg-blue-dark ">
        <div className="row">
          <div className="col-lg-4 col-6 offset-lg-4 d-flex align-items-center justify-content-center">
            <span className="text-white">
              <strong>
                {currentPage} / {totalPageCount}
              </strong>
            </span>
          </div>
          <div className="col-lg-4 col-6">
            <div className="container-full">
              <div className="row">
                <div className="pagination justify-content-end">
                  <div className="pe-3">
                    <button
                      className="btn btn-outline-light me-1 px-0"
                      onClick={goToPreviousPage}
                      disabled={currentPage === 1}
                    >
                      Back
                    </button>
                    {isLastModule && isLastSection && isLastLesson ? null : (
                      <button
                        onClick={goToNextPage}
                        className="btn btn-primary"
                        style={{ width: isLastLesson ? '120px' : '' }}
                      >
                        {isLastLesson && !isLastSection
                          ? 'Next section'
                          : isLastLesson && isLastSection
                          ? 'Next module'
                          : 'Next'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  data: PropTypes.array,
  RenderComponent: PropTypes.func,
  title: PropTypes.string,
  slug: PropTypes.string,
  moduleSlug: PropTypes.string,
  sectionSlug: PropTypes.string,
  course: PropTypes.string,
  ordLesson: PropTypes.string,
  buttonConst: PropTypes.number,
  contentPerPage: PropTypes.number,
  siblingCount: PropTypes.number,
};

export default Pagination;
