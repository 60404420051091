import React, { useState, useEffect } from 'react';
import styles from './IndependenceMessages.module.css';

import img1 from './img/independence_ukraine1.jpg';
import img2 from './img/independence_ukraine_2.jpg';
import img3 from './img/independence_ukraine_3.jpg';
import img4 from './img/independence_ukraine_4.jpg';
import img5 from './img/independence_ukraine_5.jpg';
import img6 from './img/independence_ukraine_6.jpg';
import img7 from './img/independence_ukraine_7.jpg';
import img8 from './img/independence_ukraine_8.jpg';
import img9 from './img/independence_ukraine_9.jpg';
import img10 from './img/independence_ukraine_10.jpg';
import img11 from './img/independence_ukraine_11.jpg';
import img12 from './img/independence_ukraine_12.jpg';
import img13 from './img/independence_ukraine_13.jpg';
import img14 from './img/independence_ukraine_14.jpg';
import img15 from './img/independence_ukraine_15.jpg';
import symbol from './img/symbol_ukraine.png';

const images = [
  { id: '1', image: img1 },
  { id: '2', image: img2 },
  { id: '3', image: img3 },
  { id: '4', image: img4 },
  { id: '5', image: img5 },
  { id: '6', image: img6 },
  { id: '7', image: img7 },
  { id: '8', image: img8 },
  { id: '9', image: img9 },
  { id: '10', image: img10 },
  { id: '11', image: img11 },
  { id: '12', image: img12 },
  { id: '13', image: img13 },
  { id: '14', image: img14 },
  { id: '15', image: img15 },
];

const IndependenceMessages = () => {
  const [showRandomMessage, setShowRandomMessage] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [genActived, setGenActived] = useState(false);
  const [usedMessages, setUsedMessages] = useState([]);

  // Gera uma imagem randomica quando o componente monta
  useEffect(() => {
    getRandomMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRandomMessage = () => {
    setGenActived(true);
    let availableMessages = images.filter(
      (message) => !usedMessages.includes(message.id),
    );

    if (availableMessages.length === 0) {
      // Se todas as imagens já foram usadas, reinicie a lista
      setUsedMessages([]);
      availableMessages = images;
    }

    const randomMessage =
      availableMessages[Math.floor(Math.random() * availableMessages.length)];

    setSelectedMessage(randomMessage);
    setUsedMessages((prevUsed) => [...prevUsed, randomMessage.id]);
    setShowRandomMessage(true);
  };

  const handleShare = () => {
    if (selectedMessage) {
      const imageUrl = selectedMessage.image;
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'share.jpg', { type: 'image/jpeg' });

          if (navigator.share) {
            const filesArray = [file];
            navigator
              .share({
                files: filesArray,
                title: 'Slava Ukraini!',
              })
              .catch((error) => console.error('Error sharing', error));
          } else {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'share.jpg';
            link.click();
          }
        })
        .catch((error) => console.error('Error fetching image', error));
    }
  };

  const handleDownload = () => {
    if (selectedMessage) {
      const imageUrl = selectedMessage.image;
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'ukraine_independence_message.jpg';
          link.click();
        })
        .catch((error) => console.error('Error downloading image', error));
    }
  };

  return (
    <div className={`${styles.backgroundImageBottom} container-fluid`}>
      <div
        className={`${styles.backgroundGradient} row d-flex justify-content-center align-items-center`}
      >
        <div className="col-lg-10 d-flex justify-content-center align-items-center flex-column pt-5">
          <img
            src={symbol}
            alt="Ukraine symbol"
            className={`${styles.symbol} mb-4 mt-5`}
          />
          <h1
            className={`${
              genActived ? 'homeH1Active' : 'homeH1'
            } text-white text-wrap text-lg-end text-center`}
          >
            Celebrate Ukraine&apos;s independence!
          </h1>
          <h4
            className={`${
              genActived ? 'homeH4Active' : 'homeH4'
            } text-white text-wrap text-lg-center text-center w-100`}
          >
            Show your support! 🇺🇦 #UkraineIndependenceDay <br /> Choose your
            favorite image using the yellow button below.
          </h4>
          <div
            className={`mt-lg-3 col-lg-6  d-flex flex-column justify-content-center align-items-center`}
          >
            {selectedMessage && (
              <div className="py-3 px-4">
                <img
                  src={selectedMessage.image}
                  alt="Selected message"
                  style={{
                    width: '100%',
                    borderRadius: '15px',
                    marginBottom: '20px',
                  }}
                />
                <div className="d-flex flex-column align-items-center justify-content-center mt-0 mb-5">
                  <button
                    className="btn btn-warning rounded-pill px-4 py-2 fw-bold mt-0"
                    style={{ width: '200px' }}
                    onClick={getRandomMessage}
                  >
                    Get another image
                  </button>
                  {/* <button
                    className="btn btn-primary rounded-pill px-4 py-2 fw-bold mt-2"
                    onClick={handleShare}
                  >
                    Share
                  </button> */}
                  <p className="mt-3 mb-0 pb-0 pe-0 fw-bold text-center me-0 col-8 col-lg-12">
                    Download the image above and share with your friends:
                  </p>
                  <button
                    className="btn btn-primary rounded-pill px-4 m-0 py-2 fw-bold mt-2"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndependenceMessages;
