import React from 'react';
import PropTypes from 'prop-types';
import styles from './LogIdeas.module.css';

const RenderIdeas = ({ ideaObject }) => {
  if (!ideaObject) {
    return <div>No answers saved.</div>;
  }

  const sortedIdeas = Object.values(ideaObject).sort(
    (a, b) => b.number - a.number,
  );

  return (
    <div className="mb-md-2 mt-5 pt-4 mb-5">
      <h5 className="text-blue">
        These are your previous responses about the problem:
      </h5>
      <>
        {sortedIdeas.map((item, index) => {
          const key = Object.keys(ideaObject).find(
            (key) => ideaObject[key] === item,
          );
          if (key === 'classify') {
            return (
              <div
                key={key}
                style={{
                  backgroundColor: index === 0 ? '#d2e4ec' : 'none',
                  paddingLeft: index === 0 ? '10px' : '0',
                }}
              >
                <p className="fw-bold mb-0">{item.description}:</p>
                <div className={`${styles.graphContainer} mb-5`}>
                  <div className={`${styles.quadrant} ${styles.quadrant1}`}>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      <u>Q1 - High Impact, Low Effort</u>
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.Q1}</p>
                  </div>
                  <div className={`${styles.quadrant} ${styles.quadrant2}`}>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      <u>Q2 - High Impact, High Effort</u>
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.Q2}</p>
                  </div>
                  <div className={`${styles.quadrant} ${styles.quadrant3}`}>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      <u>Q3 - Low Impact, Low Effort</u>
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.Q3}</p>
                  </div>
                  <div className={`${styles.quadrant} ${styles.quadrant4}`}>
                    <p
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <u>Q4 - Low Impact, High Effort</u>
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.Q4}</p>
                  </div>
                  <div className={styles.horizontalAxisLabel}>
                    <span className={styles.axisLabel}>Low Effort </span>
                    <span className={styles.axisLabel}>High Effort </span>
                  </div>
                  <div className={styles.verticalAxisLabel}>
                    <span
                      className={`${styles.axisLabel} ${styles.axisLabelTop}`}
                    >
                      Low Impact
                    </span>
                    <span
                      className={`${styles.axisLabel} ${styles.axisLabelBottom}`}
                    >
                      High Impact
                    </span>
                  </div>
                </div>
              </div>
            );
          } else if (key === 'problem_rewritten') {
            return (
              <div
                key={key}
                style={{
                  backgroundColor: index === 0 ? '#d2e4ec' : 'none',
                  paddingLeft: index === 0 ? '10px' : '0',
                }}
              >
                <p className="fw-bold mb-0">
                  Your rewritten problem statement based on your
                  &rdquo;Who/When/Where and What&rdquo; answer:
                </p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{item.rewritten}</p>
                <p className="fw-bold mb-0">
                  Your answer about &rdquo;Who/When/Where and What&rdquo;:
                </p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{item.ask_yourself}</p>
              </div>
            );
          } else if (key === 'root_cause') {
            return (
              <div
                key={key}
                style={{
                  backgroundColor: index === 0 ? '#d2e4ec' : 'none',
                  paddingLeft: index === 0 ? '10px' : '0',
                }}
              >
                <p className="fw-bold mt-3 mb-0">Your root cause:</p>
                <p className="mb-3">{item.root_cause_answer}</p>
                <p className="fw-bold mb-0">Your answers for the whys:</p>
                <p className="mb-0">Why? {item.why_1}</p>
                <p className="mb-0">Why? {item.why_2}</p>
                <p className="mb-0">Why? {item.why_3}</p>
                <p className="mb-0">Why? {item.why_4}</p>
                <p className="mb-2">Why? {item.why_5}</p>
              </div>
            );
          } else if (key === 'define') {
            return (
              <div
                key={key}
                style={{
                  backgroundColor: index === 0 ? '#d2e4ec' : 'none',
                  paddingLeft: index === 0 ? '10px' : '0',
                }}
                className="my-4"
              >
                <p className="fw-bold mt-3 mb-0">{item.description}:</p>

                <>
                  {item.corrective_solution && (
                    <p className="mb-0">
                      {' '}
                      Corrective solution: {item.corrective_solution}{' '}
                    </p>
                  )}
                  {item.adaptive_solution && (
                    <p className="mb-0">
                      {' '}
                      Adaptive solution: {item.adaptive_solution}{' '}
                    </p>
                  )}
                  {item.temporary_solution && (
                    <p className="mb-0">
                      {' '}
                      Temporary solution: {item.temporary_solution}{' '}
                    </p>
                  )}
                  {item.contingency_solution && (
                    <p className="mb-0">
                      {' '}
                      Contingency solution: {item.contingency_solution}{' '}
                    </p>
                  )}
                  {item.preventive_solution && (
                    <p className="mb-2">
                      {' '}
                      Preventive solution: {item.preventive_solution}{' '}
                    </p>
                  )}
                </>
              </div>
            );
          }
          return (
            <div
              key={key}
              style={{
                backgroundColor: index === 0 ? '#d2e4ec' : 'none',
                paddingLeft: index === 0 ? '10px' : '0',
              }}
            >
              <p className="fw-bold mb-0">{item.description}:</p>
              <p style={{ whiteSpace: 'pre-wrap' }}>{item.idea}</p>
            </div>
          );
        })}
      </>
    </div>
  );
};

RenderIdeas.propTypes = {
  ideaObject: PropTypes.object,
};

export default RenderIdeas;
