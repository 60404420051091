import styles from './CreatePost.module.css';
import MarkdownEditor from '@uiw/react-markdown-editor';

import React, { useRef } from 'react';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../context/AuthContext';

import { Link } from 'react-router-dom';

import { useFetchSavedPath } from '../../hooks/useFetchSavedPath';

const Create = () => {
  const { documents: courses } = useFetchSavedPath('courses');
  const [course, setCourse] = useState('');

  function onChangeCourse(event) {
    setCourse(event.target.value);
  }

  return (
    <div className="container bg-white">
      <label className="col-6">
        <span>Course:</span>
        <select
          className="form-select m-1"
          onChange={onChangeCourse}
          defaultValue={'DEFAULT'}
        >
          <option value="DEFAULT" disabled>
            Choose course
          </option>
          {courses &&
            courses.map((course) => (
              <option
                key={course.ordination}
                data-key={course.ordination}
                value={course.code}
              >
                course {course.ordination} - {course.name}
              </option>
            ))}
        </select>
        {course && (
          <div>
            <Link
              to={`/posts/createlesson/${course}`}
              className="btn btn-primary"
            >
              Create Lesson
            </Link>
            <Link
              to={`/posts/create-module/${course}`}
              className="btn btn-primary col-3 m-3"
            >
              Create Module
            </Link>
            <Link
              to={`/posts/create-section/${course}`}
              className="btn btn-primary col-3 m-3"
            >
              Create Section
            </Link>
          </div>
        )}
      </label>
    </div>
  );
};

export default Create;
