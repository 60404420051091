// CSS
import styles from './HomeCourse.module.css';

//img

// import webappcard from './img/web-app-build.png';
// import iconInformation from './img/icon-info.png';

// hooks
import { useFetchDocuments } from '../../hooks/useFetchDocuments';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useFetchCourse } from '../../hooks/useFetchCourse';

// react
import { useState } from 'react';

// components
import PostDetail from '../../components/PostDetail';
import ProgressBarCircle from '../../components/ProgressBar/ProgressBarCircle';

const HomeLesson = () => {
  const course = 'problem_solving';
  const { documents: lessons, loading } = useFetchDocuments('posts', course);
  const { documents: modules } = useFetchDocuments('modules', course);
  const { documents: sections } = useFetchDocuments('sections', course);
  const { documents: courses } = useFetchCourse('courses');

  const navigate = useNavigate();

  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (query) {
      return navigate(`/search?q=${query}`);
    }
  };

  return (
    <div className="container-full homeCourse" id="home-course">
      <div className="container text-center pb-5">
        <div className="row d-flex flex-column flex-lg-row flex-column-reverse pt-lg-2">
          <div className="col-lg-12 mb-4">
            {courses?.map((element) => {
              if (element.code === course) {
                return (
                  <div className="row" key={element.id}>
                    <div className="col-1 p-0 mt-4 me-2 d-flex align-items-start justify-content-end">
                      <img src={element.icon} className="w-100" />
                    </div>
                    <div className="col-10">
                      <h2
                        key={element.id}
                        className="mb-lg-5 mb-2 text-start py-4"
                      >
                        {element.name}
                      </h2>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>

        <div className="post-list">
          {loading && (
            <div className="container d-flex align-items-center justify-content-end">
              <p>Loading...</p>
            </div>
          )}
          <div className="d-flex flex-column-reverse flex-lg-row g-0">
            <div className="col-lg-3">
              <div className="row g-0">
                <h4 className="text-blue text-start">Coming soon:</h4>
                <a
                  className={`${styles.card_courses} p-3`}
                  style={{ cursor: 'not-allowed' }}
                >
                  <div className="d-flex">
                    <strong className="col-6">
                      <p className="text-blue fs-5 lh-1">
                        Intermediate JavaScript
                      </p>
                    </strong>
                    <div className="col-6 p-0 m-0 d-flex align-items-start justify-content-end">
                      <img src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-intermediateStroke.svg?alt=media&token=fd7f9fc5-efcc-432a-8308-4a9d6deb3c33"></img>
                    </div>
                  </div>

                  <figcaption>
                    <h4 className="text-uppercase">Coming soon</h4>
                  </figcaption>
                  <p
                    className={`${styles.textGray} lh-sm mt-lg-5 mt-4 pb-2 ps-1`}
                  >
                    Take your JavaScript to the next level by learning more
                    complex web apps using concepts such as classes, promises,
                    async/await, and requests.
                  </p>
                  <div className="ps-2 mt-2 d-flex align-items-baseline">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                      className={styles.iconLevel}
                    ></img>
                    <small className="text-blue ms-2">Intermediate</small>
                  </div>
                </a>
                <a
                  className={`${styles.card_courses} p-3 mt-3`}
                  style={{ cursor: 'not-allowed' }}
                >
                  <div className="d-flex">
                    <strong className="col-7">
                      <p className="text-blue fs-5 lh-1">
                        Interactive Websites with JS, HTML and CSS
                      </p>
                    </strong>
                    <div className="col-5 p-0 m-0 d-flex align-items-start justify-content-end">
                      <img src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-interactve.svg?alt=media&token=55d9c493-f3e6-4c6a-ba34-ac494a701cfd"></img>
                    </div>
                  </div>

                  <figcaption className={styles.interactiveCard}>
                    <h4 className="text-uppercase">Coming soon</h4>
                  </figcaption>
                  <p
                    className={`${styles.textGray} lh-sm mt-4 pt-lg-3 pt-4 pb-2 ps-2 mb-0`}
                  >
                    Join this course and embark on a journey where you will
                    learn to develop blending HTML, CSS, and JavaScript to craft
                    captivating and interactive websites.
                  </p>
                  <div className="ps-2 mt-2 d-flex align-items-baseline">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/coding-for-ukraine.appspot.com/o/icon-js-level.svg?alt=media&token=f8aa03d9-6a26-402f-9e2b-c9017ecc30bb"
                      className={styles.iconLevel}
                    ></img>
                    <small className="text-blue ms-2">Intermediate</small>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-9 ps-lg-4 ps-0">
              <div className="accordion" id="accordionModules">
                {modules &&
                  modules.map((module) => (
                    <div
                      className="accordion-item my-3 shadow-sm"
                      key={module.id}
                    >
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button accordion-module py-4 ps-4 fs-5 collapsed ${
                            module.module === 'Exercise solutions'
                              ? `${styles.accBlue} text-white`
                              : ''
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#id_${module.id}`}
                          aria-expanded="true"
                          aria-controls={module.id}
                        >
                          {module.module === 'Exercise solutions'
                            ? `${module.module}`
                            : `Module ${module.ordination} - ${module.module}`}
                        </button>
                      </h2>
                      <div
                        id={`id_${module.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        // data-bs-parent="#accordionModules"
                      >
                        <div className="">
                          <div className="">
                            {sections &&
                              sections
                                .filter((emp) => emp.moduleId === module.id)

                                .map((ord) => (
                                  <div className="ps-5 pt-3" key={ord.id}>
                                    <h6 className="d-flex flex-row-reverse justify-content-end align-items-start">
                                      <p className="">
                                        <strong>
                                          Section {ord.ordination} -{' '}
                                          {ord.section}
                                        </strong>
                                      </p>
                                    </h6>
                                    <div id={`id_${ord.id}`} className="pb-2">
                                      <div className="d-flex flex-column pe-lg-5">
                                        {lessons &&
                                          lessons
                                            .filter(
                                              (emp) => emp.sectionId === ord.id,
                                            )
                                            .map((lesson) => (
                                              <Link
                                                to={`lesson/${course}/${lesson.moduleSlug}/${lesson.sectionSlug}/${lesson.slug}/${lesson.ordination}`}
                                                state={{
                                                  from: `${lesson.sectionId}`,
                                                  fora: `${lesson.ordination}`,
                                                }}
                                                key={lesson.id}
                                                className="text-start d-inline-flex align-items-center link-lesson py-1 text-decoration-none"
                                              >
                                                <div
                                                  className={
                                                    styles.icon_container
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      lesson.image?.includes(
                                                        'cise',
                                                      )
                                                        ? '#f2e597'
                                                        : null,
                                                  }}
                                                >
                                                  <img
                                                    src={lesson.image}
                                                    width="40"
                                                  />
                                                </div>

                                                <span className="ps-2">
                                                  {lesson.title}
                                                </span>
                                              </Link>
                                            ))}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {modules && modules.length === 0 && (
                <div className={styles.modules}>
                  <p>No Modules were found.</p>
                  <Link to="/modules/create" className="btn">
                    Criar primeiro module
                  </Link>
                </div>
              )}
              {/* {modules &&
          modules.map((module) => (
            <Link
              key={module.id}
              to={`/posts/${module.id}`}
              className="btn btn-outline"
            >
              Read
            </Link>
          ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLesson;
