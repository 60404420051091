// CSS
import styles from './Post.module.css';

// hooks

import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useFetchLessons } from '../../hooks/useFetchLessons';
import OpenPagination from './components/OpenPagination';

import LessonDetail from './components/LessonDetail';

const OpenLesson = () => {
  let { moduleSlug, sectionSlug, slug, order, course } = useParams();

  const [feedback, setFeedback] = useState('');
  const [comments, setComments] = useState('');
  const [responseForm, setResponseForm] = useState(false);

  const { documents: posts, loading } = useFetchLessons(
    'posts',
    moduleSlug,
    sectionSlug,
  );

  const resetForm = useCallback(() => {
    setResponseForm(false);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
    return () => {
      document.body.style.backgroundColor = '';
      resetForm();
    };
  }, [resetForm]);

  return (
    <>
      <div className="d-flex flex-column justify-content-start bg-white lessonContent">
        {loading && (
          <div className="d-flex justify-content-center pt-5">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        )}
        {posts && (
          <>
            <OpenPagination
              data={posts}
              ordLesson={order}
              moduleSlug={moduleSlug}
              sectionSlug={sectionSlug}
              course={course}
              RenderComponent={LessonDetail}
              title="Posts"
              buttonConst={3}
              contentPerPage={1}
              siblingCount={1}
            />
          </>
        )}
      </div>
    </>
  );
};

export default OpenLesson;
