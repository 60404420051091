import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pdf } from '@react-pdf/renderer';
import MyPdfDocument from './MyPdfDocument';
import RenderIdeas from './RenderIdeas';

import styles from './LogIdeas.module.css';

const LogIdeas = ({
  previousIdea,
  logIdea,
  textareaResponse,
  descriptionIdea,
}) => {
  const [idea, setIdea] = useState('');
  const [ideas, setIdeas] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const idObject = JSON.parse(localStorage.getItem('ideas'));
  const [why1Answer, setWhy1Answer] = useState('');
  const [why2Answer, setWhy2Answer] = useState('');
  const [why3Answer, setWhy3Answer] = useState('');
  const [why4Answer, setWhy4Answer] = useState('');
  const [why5Answer, setWhy5Answer] = useState('');

  useEffect(() => {
    async function generatePdf() {
      if (logIdea && localStorage.getItem('ideas')) {
        const ideasObject = JSON.parse(localStorage.getItem('ideas'));
        const ideasArray = Object.values(ideasObject);

        if (
          logIdea === 'classify' ||
          logIdea === 'problem_rewritten' ||
          logIdea === 'root_cause' ||
          logIdea === 'define'
        ) {
          const classifyObject = ideasObject[logIdea];
          setIdea(classifyObject.idea);
          setIdeas(classifyObject);
        } else {
          const ideaValue = ideasObject[logIdea]?.idea;
          setIdea(ideaValue);
          setIdeas(ideasObject);
        }

        if (logIdea === 'pdf' && Object.keys(ideasObject).length > 0) {
          setIdeas(ideasObject);

          // Convertendo o objeto em um array de objetos e ordenando com base na propriedade "number"
          const ideasArray = Object.keys(ideasObject)
            .map((key) => ({
              number: ideasObject[key].number,
              key,
              value: ideasObject[key],
            }))
            .sort((a, b) => a.number - b.number);

          if (!pdfUrl) {
            const blob = await pdf(
              ideasArray ? <MyPdfDocument ideas={ideasArray} /> : null,
            ).toBlob();

            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
          }
        }
      }
    }

    generatePdf();
  }, [logIdea, pdfUrl]);

  const handleChange = (e) => {
    setIdea(e.target.value);
  };

  const handleButtonClick = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    }
  };

  const handleChangeClassify = (e) => {
    const { id, value } = e.target;
    setIdeas((prevIdeas) => ({
      ...prevIdeas,
      [id]: value,
    }));
  };

  const handleChangeWhy = (e) => {
    const { id, value } = e.target;
    const updatedIdeas = { ...ideas };

    if (id === 'root_cause_answer') {
      updatedIdeas[id] = value.trim() !== '' ? value.trim() : '';
    } else if (id.startsWith('why_')) {
      const whyNumber = id.split('_')[1];
      updatedIdeas[`why_${whyNumber}`] = value.trim();

      switch (whyNumber) {
        case '1':
          setWhy1Answer(value.trim());
          break;
        case '2':
          setWhy2Answer(value.trim());
          break;
        case '3':
          setWhy3Answer(value.trim());
          break;
        case '4':
          setWhy4Answer(value.trim());
          break;
        case '5':
          setWhy5Answer(value.trim());
          break;
        default:
          break;
      }
    }

    setIdeas(updatedIdeas);
  };

  const saveIdeasToLocalStorage = (updatedIdeas) => {
    localStorage.setItem('ideas', JSON.stringify(updatedIdeas));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const existingIdeas = JSON.parse(localStorage.getItem('ideas')) || {};
    let numberIdea;

    if (logIdea === 'original_idea') {
      numberIdea = 1;
    } else if (logIdea === 'problem_rewritten') {
      numberIdea = 2;
    } else if (logIdea === 'root_cause') {
      numberIdea = 3;
    } else if (logIdea === 'brainstorming') {
      numberIdea = 4;
    } else if (logIdea === 'define') {
      numberIdea = 5;
    } else if (logIdea === 'classify') {
      numberIdea = 6;
    } else if (logIdea === 'measure') {
      numberIdea = 7;
    } else if (logIdea === 'task_list') {
      numberIdea = 8;
    }

    let ideaObject = {
      number: numberIdea,
      description: descriptionIdea,
    };

    if (logIdea === 'classify') {
      ideaObject = {
        ...ideaObject,
        Q1: ideas.Q1.trim(),
        Q2: ideas.Q2.trim(),
        Q3: ideas.Q3.trim(),
        Q4: ideas.Q4.trim(),
      };
    } else if (logIdea === 'problem_rewritten') {
      ideaObject = {
        ...ideaObject,
        ask_yourself: ideas.ask_yourself ? ideas.ask_yourself.trim() : '',
        rewritten: ideas.rewritten ? ideas.rewritten.trim() : '',
      };
    } else if (logIdea === 'root_cause') {
      ideaObject = {
        ...ideaObject,
        why_1: ideas.why_1.trim(),
        why_2: ideas.why_2.trim(),
        why_3: ideas.why_3.trim(),
        why_4: ideas.why_4.trim(),
        why_5: ideas.why_5.trim(),
        root_cause_answer: ideas.root_cause_answer
          ? ideas.root_cause_answer.trim()
          : '',
      };
    } else if (logIdea === 'define') {
      ideaObject = {
        ...ideaObject,
        corrective_solution: ideas.corrective_solution.trim(),
        adaptive_solution: ideas.adaptive_solution.trim(),
        temporary_solution: ideas.temporary_solution.trim(),
        contingency_solution: ideas.contingency_solution.trim(),
        preventive_solution: ideas.preventive_solution.trim(),
      };
    } else {
      const newIdea = idea.trim();
      if (newIdea !== '') {
        ideaObject = {
          ...ideaObject,
          idea: newIdea,
        };
      }
    }

    const updatedIdeas = {
      ...existingIdeas,
      [logIdea]: ideaObject,
    };
    setIdeas(updatedIdeas);
    saveIdeasToLocalStorage(updatedIdeas);
    setIsSaved(true);
  };

  return (
    <div>
      {logIdea !== 'pdf' ? (
        <div className="row mb-md-5">
          <div className="col-md-8">
            <div className="">
              {isSaved ? (
                <h5 className="text-blue mb-5 mb-md-2">
                  Response saved! Proceed to the next page ➜
                </h5>
              ) : (
                <>
                  {logIdea === 'classify' ? (
                    <div>
                      {idObject?.define ? (
                        <div
                          className="ms-md-5 mb-3"
                          style={{ fontSize: '17px' }}
                        >
                          <strong>Your solutions:</strong>{' '}
                          <div
                            className="text-primary"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {idObject.define.corrective_solution && (
                              <div className="mb-0">
                                Corrective solution:{' '}
                                {idObject.define.corrective_solution}
                              </div>
                            )}
                            {idObject.define.adaptive_solution && (
                              <div className="mb-0">
                                Adaptive solution:{' '}
                                {idObject.define.adaptive_solution}
                              </div>
                            )}
                            {idObject.define.temporary_solution && (
                              <div className="mb-0">
                                Temporary solution:{' '}
                                {idObject.define.temporary_solution}
                              </div>
                            )}
                            {idObject.define.contingency_solution && (
                              <div className="mb-0">
                                Contingency solution:{' '}
                                {idObject.define.contingency_solution}
                              </div>
                            )}
                            {idObject.define.preventive_solution && (
                              <div className="mb-0">
                                Preventive solution:{' '}
                                {idObject.define.preventive_solution}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <form>
                        <div className="d-flex flex-column">
                          <label htmlFor="q1">
                            Q1: High Impact, Low Effort:
                          </label>
                          <textarea
                            value={ideas.Q1 || ''}
                            onChange={handleChangeClassify}
                            id="Q1"
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="q2">
                            Q2: High Impact, High Effort:
                          </label>
                          <textarea
                            value={ideas.Q2 || ''}
                            onChange={handleChangeClassify}
                            id="Q2"
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="q3">
                            Q3: Low Impact, Low Effort:
                          </label>
                          <textarea
                            value={ideas.Q3 || ''}
                            onChange={handleChangeClassify}
                            id="Q3"
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="q1">
                            Q4: Low Impact, High Effort:
                          </label>
                          <textarea
                            value={ideas.Q4 || ''}
                            onChange={handleChangeClassify}
                            id="Q4"
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary mt-2 float-end"
                          onClick={handleSubmit}
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  ) : logIdea === 'problem_rewritten' ? (
                    <div>
                      {idObject?.original_idea?.idea ? (
                        <h5 className="ms-4 mb-3">
                          Your problem:{' '}
                          <span className="text-primary">
                            {idObject?.original_idea?.idea}
                          </span>
                        </h5>
                      ) : null}

                      <form onSubmit={handleSubmit} className="mb-3">
                        <div className="form-group">
                          <label htmlFor="text1 mt-3">
                            Based on your problem, ask yourself who, when where
                            and what:
                          </label>
                          <textarea
                            className="form-control"
                            id="ask_yourself"
                            value={
                              ideas.ask_yourself !== undefined ||
                              ideas.ask_yourself === ''
                                ? ideas.ask_yourself
                                : `Who?...\nWhen?...\nWhere?...\nWhat?...`
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="text2">
                            Rewrite your problem statement considering the
                            answers above for Who, When, Where and What:
                          </label>
                          <textarea
                            className="form-control"
                            id="rewritten"
                            value={
                              ideas.rewritten !== undefined
                                ? ideas.rewritten
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                          ></textarea>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary float-end mt-3"
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  ) : logIdea === 'define' ? (
                    <div>
                      {idObject?.brainstorming?.idea ? (
                        <h5 className="ms-md-4 mb-3">
                          Your brainstorming ideas for solution:{' '}
                          <div
                            className="text-primary"
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {idObject?.brainstorming?.idea}
                          </div>
                        </h5>
                      ) : null}

                      <form onSubmit={handleSubmit} className="mb-3">
                        <div className="form-group mt-3">
                          <label htmlFor="corrective_solution">
                            From the solution ideas you listed in the
                            brainstorming process, which one is the Corrective
                            solution?
                          </label>
                          <input
                            className="form-control"
                            id="corrective_solution"
                            value={
                              ideas.corrective_solution !== undefined
                                ? ideas.corrective_solution
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                            placeholder="Enter the Corrective solution..."
                          ></input>
                          <label htmlFor="adaptive_solution" className=" mt-3">
                            From the solution ideas you listed in the
                            brainstorming process, which one is the Adaptive
                            solution?
                          </label>
                          <input
                            className="form-control"
                            id="adaptive_solution"
                            value={
                              ideas.adaptive_solution !== undefined
                                ? ideas.adaptive_solution
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                            placeholder="Enter the Adaptive solution..."
                          ></input>
                          <label htmlFor="temporary_solution" className=" mt-3">
                            From the solution ideas you listed in the
                            brainstorming process, which one is the Temporary
                            solution?
                          </label>
                          <input
                            className="form-control"
                            id="temporary_solution"
                            value={
                              ideas.temporary_solution !== undefined
                                ? ideas.temporary_solution
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                            placeholder="Enter the Temporary solution..."
                          ></input>
                          <label
                            htmlFor="contingency_solution"
                            className=" mt-3"
                          >
                            From the solution ideas you listed in the
                            brainstorming process, which one is the Contingency
                            solution?
                          </label>
                          <input
                            className="form-control"
                            id="contingency_solution"
                            value={
                              ideas.contingency_solution !== undefined
                                ? ideas.contingency_solution
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                            placeholder="Enter the Contingency solution..."
                          ></input>
                          <label
                            htmlFor="preventive_solution"
                            className=" mt-3"
                          >
                            From the solution ideas you listed in the
                            brainstorming process, which one is the Preventive
                            solution?
                          </label>
                          <input
                            className="form-control"
                            id="preventive_solution"
                            value={
                              ideas.preventive_solution !== undefined
                                ? ideas.preventive_solution
                                : ``
                            }
                            onChange={handleChangeClassify}
                            rows="4"
                            placeholder="Enter the Preventive solution..."
                          ></input>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary float-end mt-3"
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  ) : logIdea === 'root_cause' ? (
                    <div>
                      {idObject?.problem_rewritten?.rewritten ? (
                        <h5 className="ms-4 mb-3">
                          Your problem:{' '}
                          <span className="text-primary">
                            {idObject?.problem_rewritten?.rewritten}
                          </span>
                        </h5>
                      ) : null}

                      <form onSubmit={handleSubmit} className="mb-3">
                        <div className="form-group">
                          <label htmlFor="why_1 mt-3">
                            Why{' '}
                            {idObject?.problem_rewritten?.rewritten &&
                              ` "${idObject?.problem_rewritten?.rewritten}"`}
                            ?
                          </label>
                          <input
                            className="form-control"
                            id="why_1"
                            value={ideas.why_1 !== undefined ? ideas.why_1 : ''}
                            onChange={handleChangeWhy}
                            rows="4"
                            placeholder="Enter your answer..."
                          ></input>
                          <div className=" mt-4">
                            <label htmlFor="why_2">
                              Why{' '}
                              {why1Answer ? `"${why1Answer}"?` : ideas.why_1}?
                            </label>
                            <input
                              className="form-control"
                              id="why_2"
                              value={
                                ideas.why_2 !== undefined ? ideas.why_2 : ''
                              }
                              onChange={handleChangeWhy}
                              rows="4"
                              placeholder="Enter your answer..."
                            ></input>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="why_3">
                              Why {why2Answer ? `"${why2Answer}"` : ideas.why_2}
                              ?
                            </label>
                            <input
                              className="form-control"
                              id="why_3"
                              value={
                                ideas.why_3 !== undefined ? ideas.why_3 : ''
                              }
                              onChange={handleChangeWhy}
                              rows="4"
                              placeholder="Enter your answer..."
                            ></input>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="why_4">
                              Why {why3Answer ? `"${why3Answer}"` : ideas.why_3}
                              ?
                            </label>
                            <input
                              className="form-control"
                              id="why_4"
                              value={ideas.why_4}
                              onChange={handleChangeWhy}
                              rows="4"
                              placeholder="Enter your answer..."
                            ></input>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="why_5">
                              Why{' '}
                              {why4Answer ? `"${why4Answer}"?` : ideas.why_4}::
                            </label>
                            <input
                              className="form-control"
                              id="why_5"
                              value={
                                ideas.why_5 !== undefined ? ideas.why_5 : ''
                              }
                              onChange={handleChangeWhy}
                              rows="4"
                              placeholder="Enter your answer..."
                            ></input>
                          </div>
                          <div className="mt-4  border border-secondary p-2">
                            <label htmlFor="why_5">
                              Based on your &quot;whys&quot; answer above, your
                              root cause is:
                            </label>
                            <input
                              className="form-control"
                              id="root_cause_answer"
                              value={ideas.root_cause_answer}
                              onChange={handleChangeWhy}
                              rows="4"
                              placeholder="Enter your answer..."
                            ></input>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary float-end mt-3"
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  ) : (
                    <div>
                      {logIdea === 'brainstorming' ? (
                        idObject?.root_cause?.root_cause_answer ? (
                          <h5 className=" mb-3">
                            The root cause of your problem:{' '}
                            <span className="text-primary">
                              {idObject?.root_cause?.root_cause_answer}
                            </span>
                          </h5>
                        ) : null
                      ) : null}

                      <form
                        onSubmit={handleSubmit}
                        className="w-100 mw-100 mb-2"
                        id="logIdea"
                      >
                        <div className="mb-1">{textareaResponse}:</div>
                        <textarea
                          className="w-100"
                          value={idea}
                          onChange={handleChange}
                          rows="4"
                          placeholder="Enter your answer here"
                        ></textarea>
                        <button
                          type="submit"
                          className="btn btn-primary float-end"
                        >
                          Add
                        </button>
                      </form>
                    </div>
                  )}

                  <RenderIdeas
                    ideaObject={JSON.parse(localStorage.getItem('ideas'))}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(ideas).length !== 0 ? (
            <div className="row">
              <div className="col-md-8">
                {' '}
                <button
                  type="button"
                  onClick={handleButtonClick}
                  className="btn btn-primary"
                  disabled={!pdfUrl}
                  style={{ width: '250px' }}
                >
                  Generate your log ideas to PDF
                </button>
                <RenderIdeas
                  ideaObject={JSON.parse(localStorage.getItem('ideas'))}
                />
              </div>
            </div>
          ) : (
            <h5 className="text-center text-danger mt-5">
              No ideas to generate PDF
            </h5>
          )}
        </>
      )}
    </div>
  );
};

LogIdeas.propTypes = {
  previousIdea: PropTypes.string,
  logIdea: PropTypes.string.isRequired,
  textareaResponse: PropTypes.string.isRequired,
  descriptionIdea: PropTypes.string,
};

export default LogIdeas;
