import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
  Link,
  PDFViewer,
} from '@react-pdf/renderer';

import styles from './LogIdeas.module.css';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' }, // font-style: normal, font-weight: normal
    { src: '/fonts/Roboto-Light.ttf', fontWeight: 'light' },
    { src: '/fonts/Roboto-Medium.ttf', fontWeight: 'medium' },
  ],
});

import PropTypes from 'prop-types';
import logo from './img/cfu_logo.png';

const MyPdfDocument = ({ ideas }) => (
  // <PDFViewer showToolbar={true} width="100%" height="600">
  <Document>
    <Page
      size="A4"
      key={ideas}
      styles={{
        flexDirection: 'row',
        fontSize: '13px',
        marginTop: '40px',
        paddingRight: '20px',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '15px',
          padding: '15px',
        }}
      >
        <Image
          src={logo}
          style={{
            width: '172px',
            height: 'auto',
          }}
        />
        <Text
          style={{
            marginTop: '0px',
            marginLeft: '20px',
            marginRight: '20px',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '5px',
            color: '#286AFF',
            wordWrap: 'break-word',
            width: '100%',
          }}
        >
          Course: Problem Solving for Everyday Life
        </Text>
      </View>
      <View>
        <Text
          style={{
            marginLeft: '30px',

            fontSize: '20px',
            fontWeight: 'bold',

            wordWrap: 'break-word',
            width: '100%',
          }}
        >
          Your idea log
        </Text>
      </View>
      {ideas.map((item, index) => {
        if (item.key === 'classify') {
          return (
            <React.Fragment key={index}>
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                  marginTop: '25px',
                  marginLeft: '25px',
                  color: '#286AFF',
                }}
              >
                {item.value.description}:
              </Text>
              <View
                wrap={true}
                style={{
                  width: '530px',
                  height: '330px',
                  margin: '50px auto',
                  marginBottom: '0px',
                  position: 'relative',
                  borderLeft: '1px solid #0c0c0c',
                  borderBottom: '1px solid #0c0c0c',
                  boxSizing: 'border-box',
                  padding: '10px',
                  marginLeft: '35px',
                }}
              >
                <View
                  style={{
                    width: 'calc(50% - 15px)',
                    height: 'calc(50% - 15px)',
                    boxSizing: 'border-box',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    backgroundColor: '#40739d',
                    position: 'absolute',
                    top: 0,
                    marginLeft: '10px',
                    left: 0,
                  }}
                >
                  <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
                    Q1 - High Impact, Low Effort
                  </Text>
                  <Text style={{ fontSize: '10px' }}>{item.value.Q1}</Text>
                </View>
                <View
                  style={{
                    width: 'calc(50% - 15px)',
                    height: 'calc(50% - 15px)',
                    boxSizing: 'border-box',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    backgroundColor: '#0097b2',
                    position: 'absolute',
                    marginLeft: '10px',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
                    Q2 - High Impact, High Effort
                  </Text>
                  <Text style={{ fontSize: '10px' }}>{item.value.Q2}</Text>
                </View>
                <View
                  style={{
                    width: 'calc(50% - 15px)',
                    height: 'calc(50% - 15px)',
                    boxSizing: 'border-box',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    backgroundColor: '#ff5757',
                    position: 'absolute',
                    bottom: 0,
                    marginLeft: '10px',
                    marginBottom: '10px',
                    left: 0,
                  }}
                >
                  <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
                    Q3 - Low Impact, Low Effort
                  </Text>
                  <Text style={{ fontSize: '10px' }}>{item.value.Q3}</Text>
                </View>
                <View
                  style={{
                    width: 'calc(50% - 15px)',
                    height: 'calc(50% - 15px)',
                    boxSizing: 'border-box',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    backgroundColor: '#d1a91a',
                    position: 'absolute',
                    bottom: 0,
                    marginLeft: '10px',
                    marginBottom: '10px',
                    right: 0,
                  }}
                >
                  <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
                    Q4 - Low Impact, High Effort
                  </Text>
                  <Text style={{ fontSize: '10px' }}>{item.value.Q4}</Text>
                </View>
                <View
                  style={{
                    position: 'absolute',
                    flexDirection: 'row',
                    bottom: '-15px',
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    fontSize: '8px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text>Low Effort</Text>
                  <Text>High Effort</Text>
                </View>
                {/* <View
                  style={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(270deg)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '8px',
                    left: '-275px',
                  }}
                >
                  <Text style={{ left: '-55px' }}>Low Impact</Text>
                  <Text style={{ left: '-240px' }}>High Impact</Text>
                </View> */}
              </View>
            </React.Fragment>
          );
        } else if (item.key === 'problem_rewritten') {
          return (
            <View
              key={item.key}
              wrap={false}
              style={{
                marginTop: '35px',
                marginBottom: '0px',
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  color: '#286AFF',
                }}
              >
                Your answer about &rdquo;Who/When/Where and What&rdquo;:
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                  marginBottom: '20px',
                }}
              >
                {item.value.ask_yourself}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  color: '#286AFF',
                }}
              >
                Your rewritten problem statement based on your
                &rdquo;Who/When/Where and What&rdquo; answer:
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.rewritten}
              </Text>
            </View>
          );
        } else if (item.key === 'root_cause') {
          return (
            <View
              key={item.key}
              wrap={false}
              style={{
                marginTop: '35px',
                marginBottom: '0px',
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  color: '#286AFF',
                }}
              >
                Your answers for the whys:
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.why_1 ? `Why? ${item.value.why_1}` : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.why_2 ? `Why? ${item.value.why_2}` : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.why_3 ? `Why? ${item.value.why_3}` : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.why_4 ? `Why? ${item.value.why_4}` : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.why_5 ? `Why? ${item.value.why_5}` : null}
              </Text>
            </View>
          );
        } else if (item.key === 'define') {
          return (
            <View
              key={item.key}
              wrap={false}
              style={{
                marginTop: '35px',
                marginBottom: '0px',
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  color: '#286AFF',
                }}
              >
                {item.value.description}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.corrective_solution
                  ? `Corrective solution: ${item.value.corrective_solution}`
                  : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.adaptive_solution
                  ? `Adaptive solution: ${item.value.adaptive_solution}`
                  : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.temporary_solution
                  ? `Temporary solution: ${item.value.temporary_solution}`
                  : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.contingency_solution
                  ? `Contingency solution: ${item.value.contingency_solution}`
                  : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value.preventive_solution
                  ? `Preventive solution: ${item.value.preventive_solution}`
                  : null}
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.why_2}
              </Text>
            </View>
          );
        } else {
          return (
            <View
              key={item.key}
              wrap={false}
              style={{
                marginTop: '35px',
                marginBottom: '0px',
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <Text
                wrap={true}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  color: '#286AFF',
                }}
              >
                {item.value?.description}:
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: '13px',
                  color: '#1E2020',
                }}
              >
                {item.value?.idea}
              </Text>
            </View>
          );
        }
      })}
    </Page>
  </Document>
);

MyPdfDocument.propTypes = {
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      Q1: PropTypes.string,
      Q2: PropTypes.string,
      Q3: PropTypes.string,
      Q4: PropTypes.string,
      idea: PropTypes.string,
    }),
  ),
};

export default MyPdfDocument;
